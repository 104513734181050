<template>
  <div class="Category content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="分类名称">
              <el-input
                v-model="Name"
                @clear="handleSearch"
                clearable
                placeholder="输入分类名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                @change="handleSearch"
                v-model="Active"
                placeholder="请选择"
                clearable
              >
                <el-option label="有效" :value="true"></el-option>
                <el-option label="无效" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog"
            >添加分类</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table
        size="small"
        :data="tableData"
        row-key="CategoryID"
        :tree-props="{ children: 'Child' }"
      >
        <el-table-column prop="Name" label="分类名称"></el-table-column>
        <el-table-column label="移动" min-width="180px">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              circle
              icon="el-icon-upload2"
              :disabled="scope.$index == 0"
              @click="upOneClick(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              :disabled="scope.$index == 0"
              icon="el-icon-top"
              @click="upClick(scope.row, scope.$index)"
            ></el-button>
            <el-button
              size="small"
              type="primary"
              circle
              :disabled="scope.$index == tableDataLength - 1"
              icon="el-icon-bottom"
              @click="downClick(scope.row, scope.$index)"
            ></el-button>
            <!-- scope.$index -->
            <el-button
              size="small"
              type="primary"
              circle
              :disabled="scope.$index == tableDataLength - 1"
              icon="el-icon-download"
              @click="downOneClick(scope.row, scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="Active"
          label="有效性"
          :formatter="formatStatus"
        ></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(2, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(1, scope.row)"
              v-if="scope.row.ParentID == 0"
              >添加子分类</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分类弹窗-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      @closed="dialogClose('ruleForm1')"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm1"
          label-width="120px"
          size="small"
        >
          <el-form-item label="分类名称" prop="Name">
            <el-input v-model="ruleForm.Name" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-if="isadd == 2">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="addSubmit('ruleForm1')"
          :loading="modalLoading"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--子分类弹窗-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogSubClass"
      width="30%"
      @closed="dialogClose('ruleForm2')"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm2"
          label-width="120px"
          size="small"
          class="demo-ruleForm"
        >
          <el-form-item
            label="上级分类"
            prop="ParentID"
            v-show="ruleForm.ParentID != 0"
          >
            <el-select
              v-model="ruleForm.ParentName"
              placeholder="请选择分类"
              disabled
            >
              <!-- <el-option
                v-for="(category, index) in projectCategory"
                :key="index"
                :label="category.Name"
                :value="category.ID"
              ></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="分类名称" prop="Name">
            <el-input clearable v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-show="isadd == 2">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogSubClass = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="addSubmit('ruleForm2')"
          :loading="modalLoading"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/EShopManage/EShopGoodsCategory";
export default {
  name: "Category",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      modalLoading: false,
      Name: "", //商品分类输入值
      Active: "", //商品有效性输入值
      dialogVisible: false, //添加商品分类弹出层布尔值
      dialogSubClass: false, //添加商品子分类弹出层布尔值
      dialogTitle: "", //弹出层标题
      isadd: 1, //1添加， 2编辑
      ruleForm: {
        //弹出层值
        Name: "",
        ParentID: 0,
        Active: "",
        CategoryID: "",
      },
      rules: {
        //验证规则
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      },
      tableData: [], //表格展示数据
      moveIndex: "", //区分点击的移动图标
      moveID: "", //当前移动的商品ID
    };
  },
  /**计算属性  */
  computed: {
    tableDataLength() {
      var tableData = this.tableData;
      var length = 0;
      if (tableData && tableData.length > 0) {
        tableData.forEach((item, index) => {
          if (item.Child && index != tableData.length - 1) {
            length += item.Child.length;
          }
          length += 1;
        });
      }
      return length;
    },
  },
  /**  方法集合  */
  methods: {
    // 获取分类列表
    getAppletGoodsCategoryList() {
      const params = {
        Name: this.Name,
        Active: this.Active,
      };
      API.AppletGoodsCategoryList(params).then((res) => {
        if (res.StateCode == 200) {
          this.tableData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 搜索
    handleSearch() {
      this.getAppletGoodsCategoryList();
    },
    // 重置新增商品弹出层表单
    dialogClose(name) {
      this.$refs[name].resetFields();
      this.ruleForm = {
        Name: "",
        ParentID: 0,
      };
    },
    // 添加分类
    showAddDialog() {
      var that = this;
      that.isadd = 1;
      that.dialogTitle = "新增分类";
      that.dialogVisible = true;
    },
    // 分类提交
    addSubmit: function (name) {
      let that = this;
      this.$refs[name].validate((valid) => {
        if (valid) {
          // 添加分类
          if (that.isadd == 1) {
            const params = {
              Name: this.ruleForm.Name,
              ParentID: this.ruleForm.ParentID,
            };
            API.AppletGoodsCategoryAdd(params).then((res) => {
              if (res.StateCode == 200) {
                // 搜索
                that.handleSearch();
                that.dialogVisible = false;
                that.dialogSubClass = false;
                this.$message.success({
                  message: "添加成功",
                  duration: 2000,
                });
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            });
            // 修改分类
          } else {
            const params = {
              Name: this.ruleForm.Name,
              ParentID: this.ruleForm.ParentID,
              Active: this.ruleForm.Active,
              CategoryID: this.ruleForm.CategoryID,
            };
            API.AppletGoodsCategoryUpdate(params).then((res) => {
              if (res.StateCode == 200) {
                // 搜索
                that.handleSearch();
                that.dialogSubClass = false;
                that.dialogVisible = false;
                this.$message.success({
                  message: "修改成功",
                  duration: 2000,
                });
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            });
          }
        } else {
          return;
        }
      });
    },
    // 编辑分类/添加子分类
    showEditDialog: function (index, row) {
      var that = this;
      that.isadd = index;
      // 添加子分类
      if (index == 1) {
        that.ruleForm = {
          Name: "",
          ParentName: row.Name,
          ParentID: row.CategoryID,
        };
        that.dialogSubClass = true;
        that.dialogTitle = "新增子分类";
      } else {
        if (row.ParentID == 0) {
          that.dialogTitle = "编辑分类";
          this.dialogVisible = true;
          that.ruleForm = Object.assign({}, row);
        } else {
          that.dialogTitle = "编辑子分类";
          that.dialogSubClass = true;
          const name = this.tableData.find(
            (item) => item.CategoryID == row.ParentID
          ).Name;
          that.ruleForm = Object.assign({}, row);
          that.ruleForm.ParentName = name;
        }
      }
    },
    // //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "有效" : "无效";
    },
    // 移动首部
    upOneClick: function (row) {
      var that = this;
      that.moveIndex = 1;
      that.moveID = row.CategoryID;
      that.setRecursion(that.tableData);
    },
    // 移动尾部
    downOneClick: function (row, index) {
      var that = this;
      that.moveIndex = 2;
      that.moveID = row.CategoryID;
      that.setRecursion(that.tableData);
    },
    // 向上
    upClick: function (row, index) {
      var that = this;
      that.moveIndex = 3;
      that.moveID = row.CategoryID;
      that.setRecursion(that.tableData);
    },
    // 向下
    downClick: function (row, index) {
      var that = this;
      that.moveIndex = 4;
      that.moveID = row.CategoryID;
      that.setRecursion(that.tableData);
    },
    // 递归
    setRecursion(data) {
      var that = this;
      var index = data.length;
      var moveId = "",
        beforeId = "",
        destParentID = 0;
      for (let i = 0; i < index; i++) {
        if (data[i].CategoryID == that.moveID) {
          if (that.moveIndex == 1) {
            //移动顶部
            moveId = data[i].CategoryID;
            beforeId = "";
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 2) {
            //移动尾部部
            moveId = data[i].CategoryID;
            beforeId = data[index - 1].CategoryID;
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 3) {
            //向上
            moveId = data[i].CategoryID;
            destParentID = data[i].ParentID;
            if (i == 0 || i == 1) {
              beforeId = "";
            } else {
              beforeId = data[i - 2].CategoryID;
            }
          } else {
            console.log(data[i]);
            //向下
            moveId = data[i].CategoryID;
            destParentID = data[i].ParentID;
            if (i == index - 1) {
              beforeId = data[i == 0 ? 0 : i - 1].CategoryID;
            } else {
              beforeId = data[i + 1].CategoryID;
            }
          }
          console.log(moveId, beforeId, destParentID);
          that.moveCategory(moveId, beforeId, destParentID);
          return false;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 项目分类移动顺序
    moveCategory: function (moveId, beforeId, destParentID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
        DestParentID: destParentID,
      };
      API.moveCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    // 获取分类列表
    this.getAppletGoodsCategoryList();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style scoped lang="scss">
.Category {
  .martp_10 {
    height: calc(100% - 76px);
    overflow-y: auto;
  }
}
</style>
